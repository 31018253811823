<template>

  <div class="modal fade p-0" :id="`removePassengerModal${cartItemIndex}${addedServiceIndex}`" tabindex="-1"
    aria-labelledby="removePassengerModalLabel" aria-hidden="true">
    <div class="add-passenger-modal modal-dialog modal-dialog-centered">
      <div class="modal-content" :style="{ background: data.brand.whitelabel_config.modalBackgroundColor }">

        <div class="modal-body pb-0 ps-0 pe-0">
          <button type="button" :class="`btn-close remove-passenger-close-${cartItemIndex}${addedServiceIndex}`"
            data-bs-dismiss="modal" aria-label="Close">
            <svg :fill="data.brand.whitelabel_config.fontColor" height="15px" width="15px" version="1.1" id="Capa_1"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 460.775 460.775" xml:space="preserve" style="margin-bottom: 8px;">
              <path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55
          c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55
          c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505
          c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55
          l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719
          c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z" />
            </svg>
          </button>
          <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
          <div class="row m-0">
            <div class="col-1 p-0">
            </div>
            <div class="col-10 p-0" v-if="data.brand.whitelabel_config.addAllPassengersFlights == true">
              <template v-if="removePassengerConfirm == false">
                <p class="for-which-passengers pt-3 pb-3 mb-0"
                  :style="{ color: data.brand.whitelabel_config.fontColor }">
                  {{ data.serviceTexts[addedService.type].remove_attribute_for_the_flights_for_all_the_passengers_below
                  }}
                </p>
                <div class="row p-0 m-0">
                  <div class="col-12 passenger-name-container"
                    v-for="(addedPassenger, addedPassengerIndex) in addedService.addedPassengers"
                    :key="addedPassengerIndex">
                    <button class="passenger-name" v-if="!addedPassenger.removed" :style="{
                      background: data.brand.whitelabel_config.passengerButtonBackgroundColor,
                      color: data.brand.whitelabel_config.fontColor,
                      border: '1.21px solid ' + data.brand.whitelabel_config.fontColor
                    }">
                      <span class="name"> {{ addedPassenger.name }} {{ addedPassenger.last_name }} </span>
                    </button>
                    <button class="passenger-name-removed" :style="{
                      background: data.brand.whitelabel_config.passengerButtonBackgroundColor,
                      color: data.brand.whitelabel_config.fontColor,
                      border: '1.21px solid ' + data.brand.whitelabel_config.fontColor
                    }" v-else>
                      <span class="name"> {{ addedPassenger.name }} {{ addedPassenger.last_name }} </span>
                    </button>
                  </div>

                </div>
                <div class="row pt-3 pb-3 m-0">
                  <button class="remove-passenger"
                    :style="{ background: data.brand.whitelabel_config.buttonBackgroundColor, color: data.brand.whitelabel_config.buttonTextColor, border: data.brand.whitelabel_config.buttonBackgroundColor }"
                    @click="removeAllPassengersConfirmModal({ cartItemIndex, addedService, addedServiceIndex })">{{
                      data.text.remove }}</button>
                </div>
              </template>
              <template v-else>
                <div class="col-12 passenger-name-container">
                  <p class="are-you-sure-text" :style="{ color: data.brand.whitelabel_config.fontColor }"> {{
                    data.text.are_you_sure_you_want_to_remove_all_passengers }}</p>


                  <p class="are-you-sure-passenger-name" :style="{ color: data.brand.whitelabel_config.fontColor }"> </p>

                  <div class="remove-passenger-button-container mt-4">
                    <button class="remove-passenger-confirm-button" @click="removeAllPassengersConfirmed()"
                      :style="{ '--backgroundColor': data.brand.whitelabel_config.passengerButtonBackgroundColor, '--labelColor': data.brand.whitelabel_config.buttonBackgroundColor }">{{
                      data.text.yes }}</button>
                    <button class="remove-passenger-confirm-button" @click="changeRemovePassengerConfirm()"
                      :style="{ '--backgroundColor': data.brand.whitelabel_config.passengerButtonBackgroundColor, '--labelColor': data.brand.whitelabel_config.buttonBackgroundColor }">{{
                      data.text.no }}</button>
                  </div>
                </div>
                <div class="row pt-3 pb-3 m-0">
                </div>
              </template>

            </div>

            <div class="col-10 p-0" v-else>
              <template v-if="removePassengerConfirm == false">
                <p class="for-which-passengers pt-3 pb-3 mb-0"
                  :style="{ color: data.brand.whitelabel_config.fontColor }">
                  {{ data.serviceTexts[addedService.type].for_which_passengers_would_you_like_to_remove_the }}
                </p>
                <div class="row p-0 m-0">
                  <div class="col-12 passenger-name-container"
                    v-for="(addedPassenger, addedPassengerIndex) in addedService.addedPassengers"
                    :key="addedPassengerIndex">
                    <button class="passenger-name" v-if="!addedPassenger.removed" :style="{
                      background: data.brand.whitelabel_config.passengerButtonBackgroundColor,
                      color: data.brand.whitelabel_config.fontColor,
                      border: '1.21px solid ' + data.brand.whitelabel_config.fontColor
                    }"
                      @click="removePassengerConfirmModal({ cartItem, cartItemIndex, addedService, addedServiceIndex, addedPassenger, addedPassengerIndex })">
                      <span class="name"> {{ addedPassenger.name }} {{ addedPassenger.last_name }} </span>
                      <i class="fa fa-trash" aria-hidden="true"></i></button>
                    <button class="passenger-name-removed" :style="{
                      background: data.brand.whitelabel_config.passengerButtonBackgroundColor,
                      color: data.brand.whitelabel_config.fontColor,
                      border: '1.21px solid ' + data.brand.whitelabel_config.fontColor
                    }" v-else>
                      <span class="name"> {{ addedPassenger.name }} {{ addedPassenger.last_name }} </span>
                      <i class="fa fa-trash" aria-hidden="true"></i></button>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="col-12 passenger-name-container">
                  <p class="are-you-sure-text" :style="{ color: data.brand.whitelabel_config.fontColor }"> {{
                    data.text.are_you_sure_you_want_to_remove_this_passenger }}</p>


                  <p class="are-you-sure-passenger-name" :style="{ color: data.brand.whitelabel_config.fontColor }">
                    {{ removePassengerModalParams.addedPassenger.name }}
                    {{ removePassengerModalParams.addedPassenger.last_name }} </p>

                  <div class="remove-passenger-button-container mt-4">
                    <button class="remove-passenger-confirm-button" @click="removePassengerConfirmed()"
                      :style="{ '--backgroundColor': data.brand.whitelabel_config.passengerButtonBackgroundColor, '--labelColor': data.brand.whitelabel_config.buttonBackgroundColor }">{{
                      data.text.yes }}</button>
                    <button class="remove-passenger-confirm-button" @click="changeRemovePassengerConfirm()"
                      :style="{ '--backgroundColor': data.brand.whitelabel_config.passengerButtonBackgroundColor, '--labelColor': data.brand.whitelabel_config.buttonBackgroundColor }">{{
                      data.text.no }}</button>
                  </div>
                </div>
              </template>
              <div class="row pt-3 pb-3 m-0">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ['data', 'cartItem', 'cartItemIndex', 'addedService', 'addedServiceIndex'],

  computed: {
        ...mapGetters(['areAllPassengersRemoved']),
    },

  data() {
    return {
      removePassengerConfirm: false
    };
  },
  methods: {
    ...mapActions(['removePassenger', 'removeAllPassengers', 'closePassengerCartModal']),
    

    removeAllPassengersConfirmModal(params) {
      this.removePassengerModalParams = params;
      this.changeRemovePassengerConfirm()
    },

    removeAllPassengersConfirmed() {
      this.removeAllPassengers({ serviceType: this.removePassengerModalParams.addedService.type });
      this.closePassengerCartModal({ cartItemIndex: this.removePassengerModalParams.cartItemIndex, addedServiceIndex: this.removePassengerModalParams.addedServiceIndex });
    },

    removePassengerConfirmModal(params) {
      this.removePassengerModalParams = params;
      this.changeRemovePassengerConfirm()
    },

    changeRemovePassengerConfirm() {
      this.removePassengerConfirm = !this.removePassengerConfirm;
    },

    removePassengerConfirmed() {
      this.removePassenger({ cartItem: this.removePassengerModalParams.cartItem, cartItemIndex: this.removePassengerModalParams.cartItemIndex, addedService: this.removePassengerModalParams.addedService, addedServiceIndex: this.removePassengerModalParams.addedServiceIndex, addedPassenger: this.removePassengerModalParams.addedPassenger, addedPassengerIndex: this.removePassengerModalParams.addedPassengerIndex });
      if(this.areAllPassengersRemoved(this.removePassengerModalParams.addedService.addedPassengers)){
        this.closePassengerCartModal({ cartItemIndex: this.removePassengerModalParams.cartItemIndex, addedServiceIndex: this.removePassengerModalParams.addedServiceIndex });
      }
      this.changeRemovePassengerConfirm();
    }
  },

}
</script>

<style scoped>
.remove-passenger {
  /* font-family: Figtree; */
  font-size: 12px;
  font-weight: 800;
  line-height: 14.4px;
  width: 100%;
  text-align: center;
  height: 50px;
  border-radius: 18px;
  margin-top: 13px;
  margin-bottom: 13px;
}

.are-you-sure-text {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  /* color: #000000; */
}

.are-you-sure-passenger-name {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  /* color: #000000; */
}

.btn-close {
  --bs-btn-close-bg: transparent;
}

.passenger-name-container {
  padding: 8px 4px 0px 0px;
}

.add-passenger {
  /* font-family: Figtree; */
  font-size: 12px;
  font-weight: 800;
  line-height: 14.4px;
  width: 100%;
  text-align: center;
  height: 50px;
  border-radius: 18px;
  margin-top: 13px;
  margin-bottom: 13px;
}

.passenger-name {
  width: 100%;
  min-height: 36px;
  border-radius: 12.07px;
  /* border: 1.21px solid #000000;
  background-color: white;
  color: #000000; */

  /* font-family: Figtree; */
  font-size: 14px;
  font-weight: 800;
  line-height: 16.8px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px;

}

.name {
  flex: 1;
  text-align: center;
}

.passenger-name i {
  position: absolute;
  right: 10px;
}

.for-which-passengers {
  /* font-family: Figtree; */
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  /* color: #000000; */

}

.modal.fade .add-passenger-modal {
  transform: translate3d(0, 0, 0);
  align-items: flex-end;
  margin: 0px;
  --bs-modal-width: 100vw !important;
}

.modal.fade {
  --bs-modal-margin: 0rem;
}

.modal-content {
  /* min-height: 242px; */
  border-radius: 30px 30px 0px 0px;
}

.btn-close:focus {
  box-shadow: none;
}

.btn-close {
  position: absolute;
  right: 24px;
  top: 10px;
}

.remove-passenger-button-container {
  display: flex;
  justify-content: space-between;
}

.remove-passenger-confirm-button {
  width: 140px;
  min-height: 36px;
  border-radius: 12.07px;
  /* border: 1.21px solid #000000;
  background-color: white;
  color: #000000; */

  /* font-family: Figtree; */
  font-size: 14px;
  font-weight: 800;
  line-height: 16.8px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px;

  border: 1.21px solid var(--labelColor);
  /* background:  var(--backgroundColor); */
  background: var(--backgroundColor);
  color: var(--labelColor);
}

.passenger-name-removed {
  width: 100%;
  min-height: 36px;
  border-radius: 12.07px;
  /* border: 1.21px solid #000000;
  background-color: white;
  color: #000000; */

  /* font-family: Figtree; */
  font-size: 14px;
  font-weight: 800;
  line-height: 16.8px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px;
  opacity: 0.5 !important;
  /* background-color: grey !important; */
}
</style>