<template>
    <div v-if="loading" class="text-center mt-5">
        <div class="spinner-border" role="status">
        </div>
    </div>
    <div v-else>
        <div v-if="queryParams.redirect_status == 'succeeded'">
            <ThankYouView :data="data" />
        </div>
        <div v-else>
            <PaymentUnsuccessful :data="data" />
        </div>
    </div>

</template>


<script>

import { mapActions, mapState, mapGetters } from "vuex";
import ThankYouView from "./ThankYouView.vue";
import PaymentUnsuccessful from "./PaymentUnsuccessful.vue";

export default {
    components: {
        ThankYouView,
        PaymentUnsuccessful
    },

    async mounted() {
        await this.loadData({
            id: this.$route.params.id,
        });

        window.addEventListener('resize', this.reloadPage);
    },

    data() {
        return {
            queryParams: this.$route.query,  
        };
    },

    methods: {
        ...mapActions(['loadData', 'reloadPage']),
    },

    computed: {
        ...mapGetters(['disableCheckout']),
        ...mapState(['loading', 'data']),
    },

}
</script>

 