<template>
        <div class="flight-cards row">
            <div class="flight-col col-12 " v-for="(journey, journeyIndex) in journeys" :key="journeyIndex">
                <div class="flight-card card" @click="selectJourney({ journeyIndex: journeyIndex })" :style="{background: data.brand.whitelabel_config.cartBackgroundColor}">
                    <div class="row">
                        <div class="col-md-4">
                            <img class="city-image" :src="`${apiAirportUrl}${journey.arrival_airport_image}`">
                            <div class="card-img-overlay">
                                <h5 class="card-title">
                                    <div class="airline-flight-number-container"
                                        :style="{ background: data.brand.whitelabel_config.cartBackgroundColor }">
                                        <span class="flight-number"
                                            :style="{ color: data.brand.whitelabel_config.fontColor }">{{journey.airline_name}} {{journey.airline}}{{journey.flight_number}}</span>
                                    </div>

                                    <div class="passenger-icons-container">
                                        <template v-for="(passenger, index) in data.payload.passengers" :key="index">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                viewBox="9.620000000000001 0 13.32 18.51851851851852" width="15.32"
                                                height="18.51851851851852">

                                                <path :fill="data.brand.whitelabel_config.cartBackgroundColor"
                                                    d="M16.1836 6.5C14.5195 6.5 13.1836 5.16406 13.1836 3.5C13.1836 1.85938 14.5195 0.5 16.1836 0.5C17.8242 0.5 19.1836 1.85938 19.1836 3.5C19.1836 5.16406 17.8242 6.5 16.1836 6.5ZM17.3555 7.625C19.6055 7.625 21.4336 9.45312 21.4336 11.7031C21.4336 12.1484 21.0586 12.5 20.6133 12.5H11.7305C11.2852 12.5 10.9336 12.1484 10.9336 11.7031C10.9336 9.45312 12.7383 7.625 14.9883 7.625H17.3555ZM31.1836 6.5C29.5195 6.5 28.1836 5.16406 28.1836 3.5C28.1836 1.85938 29.5195 0.5 31.1836 0.5C32.8242 0.5 34.1836 1.85938 34.1836 3.5C34.1836 5.16406 32.8242 6.5 31.1836 6.5ZM32.3555 7.625C34.6055 7.625 36.4336 9.45312 36.4336 11.7031C36.4336 12.1484 36.0586 12.5 35.6133 12.5H26.7305C26.2852 12.5 25.9336 12.1484 25.9336 11.7031C25.9336 9.45312 27.7383 7.625 29.9883 7.625H32.3555Z" />
                                            </svg>
                                        </template>

                                        <span class="passenger-number" :style="{ color: data.brand.whitelabel_config.cartBackgroundColor }">
                                            {{data.payload.passengers.length}}</span>
                                    </div>
                                </h5>
                            </div>
                        </div>
                        <div class="col-8 row flight-data-container">
                            <div class="col-2">

                            </div>
                            <div class="col-3 departure-airport" :style="{ color: data.brand.whitelabel_config.fontColor }">
                                <span class="departure-date">{{ moment( journey.departure_date_utc).format("MMM D, h:mm A")  }}</span>
                                <span class="iata">{{journey.departure_airport}}</span>
                            </div>
                            <div class="col-3 flight-time" :style="{ color: data.brand.whitelabel_config.fontColor }">
                                <div class="flight-details">
                                    <span class="time">
                                        {{getHoursMinutesDifference(journey.departure_date_utc, journey.arrival_date_utc)}}
                                    </span>
                                    <span class="plane">
                                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M15.0155 6.33398C16.0116 6.33398 17.7694 7.18359 17.7694 8.20898C17.7694 9.26367 16.0116 10.084 15.0155 10.084H11.5877L8.65804 15.2402C8.48225 15.5332 8.15999 15.709 7.83772 15.709H6.1971C5.87483 15.709 5.64046 15.416 5.72835 15.123L7.16389 10.084H4.17561L2.88655 11.7832C2.79866 11.9004 2.68147 11.959 2.53499 11.959H1.30452C1.07014 11.959 0.894363 11.7832 0.894363 11.5488C0.894363 11.5195 0.894363 11.4902 0.894363 11.4609L1.83186 8.20898L0.894363 4.98633C0.894363 4.95703 0.894363 4.92773 0.894363 4.86914C0.894363 4.66406 1.07014 4.45898 1.30452 4.45898H2.53499C2.68147 4.45898 2.79866 4.54688 2.88655 4.66406L4.17561 6.33398H7.16389L5.72835 1.32422C5.64046 1.03125 5.87483 0.708984 6.1971 0.708984H7.83772C8.15999 0.708984 8.48225 0.914062 8.65804 1.20703L11.5877 6.33398H15.0155Z"
                                                :fill="data.brand.whitelabel_config.fontColor" />
                                        </svg>

                                    </span>
                                </div>
                            </div>
                            <div class="col-3 departure-airport" :style="{ color: data.brand.whitelabel_config.fontColor }">
                                <span class="departure-date">{{ moment( journey.arrival_date_utc).format("MMM D, h:mm A")  }}</span>
                                <span class="iata">{{journey.arrival_airport}}</span>
                            </div>
                            <div class="col-1 arrow-right">
                                <svg width="19" height="16" viewBox="0 0 19 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17.6094 9.30664L11.3594 15.5566C11.125 15.791 10.8125 15.9082 10.5 15.9082C10.1484 15.9082 9.83594 15.791 9.60156 15.5566C9.09375 15.0879 9.09375 14.2676 9.60156 13.7988L13.7031 9.6582H1.75C1.04688 9.6582 0.5 9.11133 0.5 8.4082C0.5 7.74414 1.04688 7.1582 1.75 7.1582H13.7031L9.60156 3.05664C9.09375 2.58789 9.09375 1.76758 9.60156 1.29883C10.0703 0.791016 10.8906 0.791016 11.3594 1.29883L17.6094 7.54883C18.1172 8.01758 18.1172 8.83789 17.6094 9.30664Z"
                                        :fill="data.brand.whitelabel_config.buttonBackgroundColor" />
                                </svg>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
export default {
    props: ['data', 'journeys', 'moment', 'apiAirportUrl'],

    computed: {
        ...mapGetters(['getHoursMinutesDifference']),
    },
    methods: {
        ...mapActions(['selectJourney']),
    },

}
</script>

<style scoped>
.flight-data-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.plane {
    display: flex;
    justify-content: center;
    margin-top: 7px
}

.flight-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.iata {
    /* color: #000000; */
    margin-top: 7px;
    font-size: 25px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
}

.departure-airport {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.flight-col {
    margin-bottom: 12px;
}

.arrow-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    cursor: pointer;

}

.flight-time {
    display: flex;
    align-items: flex-start;
}



.departure-date,
.time{
    font-size: 12px;
    font-weight: 700;
    line-height: 16.39px;
}



.passenger-number {
    font-size: 12px;
    font-weight: 700;
    line-height: 16.39px;
    text-align: left;
    margin-left: 2px;
}

.passenger-icons-container {
    position: absolute;
    height: 33.31px;
    top: 22.83px;
    margin-left: 245px;
    border-radius: 14px;
    display: flex;
    justify-content: right;
    align-items: center;
    min-width: 75px;
}

.passenger-icons-container svg {
    margin-top: 5px;
}

.airline-flight-number-container {
    position: absolute;
    height: 33.31px;
    top: 22.83px;
    margin-left: 5px;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flight-number {
    font-size: 12px;
    font-weight: 800;
    line-height: 16.39px;
    text-align: center;

    padding: 0px 15px 0px 15px;
}

.city-image {
    width: 352px;
    height: 82px;
    gap: 0px;
    opacity: 0px;
    object-fit: cover;
    filter: brightness(0.8);
}

.flight-card {
    height: 82px;
    border-radius: 30px;
    /* background-color: #ffffff; */
    overflow: hidden;
    cursor:pointer;
}

.flight-cards {
    margin-top: 95px;
}

.select-your-trip-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 63px;
}

.select-your-trip-text-continer {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;


}

.select-your-trip-text {
    font-size: 18px;
    font-weight: 600;
    line-height: 24.59px;
    text-align: center;
    color: #000000;
    margin-bottom: 0px;
}

.show-items-as-button {
    margin-left: auto;
    cursor: pointer;
}

.show-items-as-button img {
    width: 30px;
    height: 30px;

}
</style>