<template>
  <router-view/>
</template>

<style>
@font-face {
    font-family: Figtree;
    src: url('~@/assets/fonts/Figtree/Figtree-VariableFont_wght.ttf');
/* 
    font-family: Manrope;
    src: url('~@/assets/fonts/Manrope/Manrope-VariableFont_wght.ttf'); */

    font-family: Helvetica;
    src: url('~@/assets/fonts/Helvetica/Helvetica.ttf');

    font-family: Arial;
    src: url('~@/assets/fonts/Arial/ARIAL.TTF');

    font-family: sans-serif;
    src: url('~@/assets/fonts/sans-serif/SansSerifFLF.otf');

    font-family: formular-regular;
    src: url('~@/assets/fonts/formular-regular/formular-regular.woff2');

    
}
</style>
