import { loadStripe } from '@stripe/stripe-js';
export default {
  SAVE_INITIAL_DATA(state, { data, loading, commit }) {

    document.title = data.brand.whitelabel_config.tabTitle;
    const favicon = document.getElementById("favicon");
    favicon.href = state.apiUrlStorage + data.brand.whitelabel_config.favicon;
    state.data = data

    Object.entries(data.payload.journeys).forEach(([flightType, journeys]) => {
      journeys.forEach(journey => {
        journey.flight_type = flightType;

        journey.passengers = JSON.parse(JSON.stringify(data.payload.passengers));
        journey.passengers.forEach(passenger => {
          passenger.services = [];
        });

        journey.services.forEach(service => {
          // service.passengers = JSON.parse(JSON.stringify(data.payload.passengers));
          service.passengers = JSON.parse(JSON.stringify(data.payload.passengers)).map(passenger => ({
            ...passenger,
            added: false,
            paid: false,
          }));
        });

        state.journeys.push(journey);
      });
    });

    let journeys = JSON.parse(JSON.stringify(state.journeys))
    data.paidItems.forEach(paidItem => {

      const journeyIndex = journeys.findIndex(journey => journey.id === paidItem.journey_id);
      const serviceIndex = journeys[journeyIndex].services.findIndex(service => service.type === paidItem.type);

      const service = journeys[journeyIndex].services[serviceIndex];
      service.price = paidItem.price / 100;
      service.paid = true
      const passenger = journeys[journeyIndex].passengers.find(passenger => passenger.id === paidItem.passenger_id);

      commit('ADD_PASSENGER_SERVICE', { journeyIndex, service, passenger })

      commit('AUTO_SELECT_SERVICE_PASSENGER', { journeyIndex, serviceIndex, passenger })
    });

    if (data.brand.whitelabel_config.autoSelectPassengers == true) {
      let autoSelectJourneys = JSON.parse(JSON.stringify(state.journeys))
      autoSelectJourneys.forEach((journey, journeyIndex) => {
        const serviceIndex = journey.services.findIndex(service => service.type === 'check-in');
        if (serviceIndex !== -1) {
          const service = journey.services[serviceIndex];
          journey.passengers.forEach(passenger => {
            commit('ADD_PASSENGER_SERVICE', { journeyIndex, service, passenger })
            commit('AUTO_SELECT_SERVICE_PASSENGER', { journeyIndex, serviceIndex, passenger })
          });
        }
      });
    }


    commit('ADD_DEVICE_TYPE', {})
    state.loading = loading
  },


  ADD_DEVICE_TYPE(state) {
    const width = window.innerWidth;
    const mobileBreakpoint = 1000;
    state.deviceType = width < mobileBreakpoint ? 'mobile' : 'desktop';
  },

  AUTO_SELECT_SERVICE_PASSENGER(state, { journeyIndex, serviceIndex, passenger }) {
    let passengers = state.journeys[journeyIndex].services[serviceIndex].passengers
    let servicePassenger = passengers.find(p => p.id === passenger.id);
    servicePassenger.added = true;
  },

  ADD_PASSENGER_SERVICE(state, { journeyIndex, service, passenger }) {

    const existingPassengerIndex = state.journeys[journeyIndex].passengers.findIndex(p => p.id === passenger.id);
    if (existingPassengerIndex !== -1) {
      const existingServiceIndex = state.journeys[journeyIndex].passengers[existingPassengerIndex].services.findIndex(s => s.type === service.type);
      if (existingServiceIndex === -1) {
        state.journeys[journeyIndex].passengers[existingPassengerIndex].services.push(service)
      }
    }

  },

  ADDED_SERVICE_PASSENGER(state, { getters, journeyIndex, serviceIndex, passenger }) {

    let passengers = state.journeys[journeyIndex].services[serviceIndex].passengers
    let servicePassenger = passengers.find(p => p.id === passenger.id);
    servicePassenger.added = true;

    if (getters.addedAllServicePassenger(passengers)) {
      document.querySelector('.btn-close-' + journeyIndex + serviceIndex).click();
    }
  },

  next_step(state, { commit }) {
    if (state.step == 0) {
      commit('ADD_TO_CART')
    } else if (state.step == 1) {
      commit('CHECKOUT')

    }
    state.step++;
  },

  previous_step(state) {
    state.step--;
  },

  SEAT_ADD_PASSENGER_SERVICE(state, { seats, commit }) {
    seats.forEach(seat => {
      const journeyIndex = state.journeys.findIndex(journey => journey.seat_journey_id === seat.journey.id);
      if (journeyIndex !== -1) {
        const passengerIndex = state.journeys[journeyIndex].passengers.findIndex(p => p.id === seat.passenger.id);
        if (passengerIndex !== -1) {
          let serviceIndex = state.journeys[journeyIndex].services.findIndex(s => s.type === 'seat-selection');
          let service = JSON.parse(JSON.stringify(state.journeys[journeyIndex].services[serviceIndex]));
          service.price = seat.seat_price

          const passengerServiceIndex = state.journeys[journeyIndex].passengers[passengerIndex].services.findIndex(s => s.type === service.type);
          if (passengerServiceIndex === -1) {
            state.journeys[journeyIndex].passengers[passengerIndex].services.push(service)
            commit('SEAT_ADDED_SERVICE_PASSENGER', { journeyIndex, serviceIndex, seat })
          }
        }
      }

    });
  },

  CLEAR_SEATS(state) {
    state.journeys.forEach(journey => {
      journey.passengers.forEach(passenger => {
        const seatSelectionIndex = passenger.services.findIndex(service => service.type === 'seat-selection');
        if (seatSelectionIndex !== -1) {
          passenger.services.splice(seatSelectionIndex, 1);
        }
      });

      const seatService = journey.services.find(service => service.type === 'seat-selection');
      if (seatService) {
        seatService.passengers = JSON.parse(JSON.stringify(state.data.payload.passengers)).map(passenger => ({
          ...passenger,
          added: false
        }));
      }
    });
  },


  SEAT_ADDED_SERVICE_PASSENGER(state, { journeyIndex, serviceIndex, seat }) {
    let passengers = state.journeys[journeyIndex].services[serviceIndex].passengers
    let servicePassenger = passengers.find(p => p.id === seat.passenger.id);
    servicePassenger.added = true;
  },


  ADD_TO_CART(state) {
    state.cartItems = [];
    state.tatalPrice = 0;
    let journeys = JSON.parse(JSON.stringify(state.journeys));
    journeys.forEach(journey => {
      journey.passengers.forEach(passenger => {
        passenger.services.forEach(service => {
          if (service.paid !== true) {
            const passengerData = { ...passenger, price: service.price };
            state.tatalPrice += service.price;

            let journeyIndex = state.cartItems.findIndex(cartItem => cartItem.id === journey.id);
            if (journeyIndex !== -1) {
              let serviceIndex = state.cartItems[journeyIndex].addedServices.findIndex(addedService => addedService.type === service.type);
              if (serviceIndex !== -1) {
                let passengerIndex = state.cartItems[journeyIndex].addedServices[serviceIndex].addedPassengers.findIndex(addedPassenger => addedPassenger.id === passengerData.id);
                if (passengerIndex === -1) {
                  state.cartItems[journeyIndex].addedServices[serviceIndex].addedPassengers.push(passengerData);
                }
              } else {
                let addedService = {
                  ...service,
                  addedPassengers: [{
                    ...passengerData
                  }]
                };
                state.cartItems[journeyIndex].addedServices.push(addedService)
              }
            } else {
              const cartItem = {
                ...journey,
                addedServices: [{
                  ...service,
                  addedPassengers: [{
                    ...passengerData
                  }]
                }]
              };
              state.cartItems.push(cartItem);
            }
          }
        })
      });

    })
  },


  REMOVE_PASSENGER(state, { cartItem, cartItemIndex, addedService, addedServiceIndex, addedPassengerIndex }) {
    state.tatalPrice -= addedService.addedPassengers[addedPassengerIndex].price;
    addedService.addedPassengers[addedPassengerIndex].removed = true;
    addedService.addedPassengers[addedPassengerIndex].price = 0;
  },

  REMOVE_PASSENGER_SERVICE(state, { cartItem, addedService, addedPassenger }) {
    let journey = state.journeys.find(journey => journey.id === cartItem.id)
    if (journey) {
      let passengerIndex = journey.passengers.findIndex(passenger => passenger.id === addedPassenger.id)
      if (passengerIndex !== -1) {

        let serviceIndex = journey.services.findIndex(service => service.type === addedService.type);
        let servicePassenger = journey.services[serviceIndex].passengers.find(p => p.id === addedPassenger.id)
        servicePassenger.added = false

        let passengerServiceIndex = journey.passengers[passengerIndex].services.findIndex(service => service.type === addedService.type);
        journey.passengers[passengerIndex].services.splice(passengerServiceIndex, 1);
      }
    }
  },

  async CHECKOUT(state) {

    // try {
    state.loadingStripe = true;
    let servicesData = [];

    state.cartItems.forEach(item => {
      item.addedServices.forEach(addedService => {
        addedService.addedPassengers.forEach(addedPassenger => {

          if (addedPassenger.removed != true) {
            servicesData.push({
              journey_id: item.id,
              passenger_id: addedPassenger.id,
              type: addedService.type,
            });
          }
        });
      });
    });

    const { data } = await window.axios.post(
      process.env.VUE_APP_API_V2_URL + `ancillaries/all-in-one/checkout/${state.data.request_id}`, {
      services: servicesData
    },
    );

    state.stripeData = data.data;
    state.loadingStripe = false;
    // } catch (error) {
    //   console.log(error)
    // }

  },

  async LOAD_STRIPE(state) {
    const stripeKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    state.stripe = await loadStripe(stripeKey);

    const options = {
      clientSecret: state.stripeData.client_secret,
      appearance: {
        variables: {
          // colorPrimary: state.data.brand.whitelabel_config.fontColor,  
          colorBackground: state.data.brand.whitelabel_config.cartBackgroundColor,
          colorText: state.data.brand.whitelabel_config.fontColor,
        },
      },
      locale: state.data.payload.lang,
    };

    state.stripeElements = state.stripe.elements(options);
    const paymentElement = state.stripeElements.create('payment');
    paymentElement.mount('#payment-element');

  },

  async HANDLE_SUBMIT(state) {

    const returnUrl = new URL("payment-response/" + state.data.request_id, window.location.origin)
    state.isStripeSubmitting = true;
    try {
      const { error, paymentIntent } = await state.stripe.confirmPayment({
        elements: state.stripeElements,
        redirect: 'if_required',
        confirmParams: {
          return_url: returnUrl.toString(),  
        },
      });
     
      if (error) {
        state.errorMessage = error.message;
        state.isStripeSubmitting = false;
      } else {
        state.step = 3;
      }
    } catch (err) {
      state.errorMessage = err.message;
      state.isStripeSubmitting = false;
    }


  },


  CHANGE_SHOW_ITEM_TYPE(state) {
    state.showItemsAsIcon = !state.showItemsAsIcon
  },

  SELECT_JOURNEY(state, { journeyIndex }) {
    state.selectedJourneyIndex = journeyIndex;
  },

  UNSELECT_JOURNEY(state) {
    state.selectedJourneyIndex = null;
  },

  ADDED_SERVICE_FOR_ALL_PASSENGERS_FLIGHTS(state, { commit, getters, currentJourneyIndex, currentService, currentServiceIndex }) {
    let journeys = JSON.parse(JSON.stringify(state.journeys))
    journeys.forEach((journey, journeyIndex) => {
      const serviceIndex = journey.services.findIndex(service => service.type === currentService.type);
      if (serviceIndex !== -1) {
        const service = journey.services[serviceIndex];
        service.passengers.forEach(passenger => {
          commit('ADD_PASSENGER_SERVICE', { journeyIndex, service, passenger })
          commit('AUTO_SELECT_SERVICE_PASSENGER', { getters, journeyIndex, serviceIndex, passenger });
        });
      }
    });
    document.querySelector('.btn-close-' + currentJourneyIndex + currentServiceIndex).click();
  },

  REMOVE_ALL_PASSENGERS(state, { commit,serviceType }){
    state.cartItems.forEach((cartItem, cartItemIndex) => {
      cartItem.addedServices.forEach((addedService, addedServiceIndex) => {
        if(addedService.type == serviceType){
          addedService.addedPassengers.forEach((addedPassenger, addedPassengerIndex) => {
            commit('REMOVE_PASSENGER', { cartItem, cartItemIndex, addedService, addedServiceIndex, addedPassengerIndex })
            commit('REMOVE_PASSENGER_SERVICE', { cartItem, addedService, addedPassenger })
          });
        }
      });
    })
  },

  CLOSE_PASSENGER_CART_MODAL(state, { cartItemIndex,  addedServiceIndex }){
    document.querySelector('.remove-passenger-close-' +  cartItemIndex + addedServiceIndex).click();
  },


  RELOAD_TO_PATH(state, {path}){
    const url = new URL(path, window.location.origin)
    window.location.href = url.toString()
  }
}  