import { createRouter, createWebHistory } from 'vue-router'
import AncillaryBrandView from '../components/AncillaryBrandView.vue'
import PaymentResponse from '../components/PaymentResponse.vue'

const routes = [
  {
    path: '/:id',
    name: 'AncillaryBrand',
    component: AncillaryBrandView
  },
  {
    path: '/payment-response/:id',
    name: 'PaymentResponse',
    component: PaymentResponse
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  
  routes
})

export default router
