<template>
  <div class="wrapper" :style="{background: data.brand.whitelabel_config.mainBackgroundColor}">
    <div class="cart-container">
   <div class="row your-cart">
        <div class="col-1">
        </div>
        <div class="col-10">
            <div class="svg-knob-elevation-and-text" @click="previousStep()">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.603448" y="0.603448" width="23.7931" height="23.7931" rx="9.39655"   :stroke="data.brand.whitelabel_config.fontColor"
                        stroke-width="1.2069" />
                    <path
                        d="M14 17.75C13.7891 17.75 13.6016 17.6797 13.4609 17.5391L8.96094 13.0391C8.65625 12.7578 8.65625 12.2656 8.96094 11.9844L13.4609 7.48438C13.7422 7.17969 14.2344 7.17969 14.5156 7.48438C14.8203 7.76562 14.8203 8.25781 14.5156 8.53906L10.5547 12.5L14.5156 16.4844C14.8203 16.7656 14.8203 17.2578 14.5156 17.5391C14.375 17.6797 14.1875 17.75 14 17.75Z"
                        :fill="data.brand.whitelabel_config.fontColor" />
                </svg>
                <p class="your-cart-text mb-0" :style="{ color: data.brand.whitelabel_config.fontColor }">{{ data.text.your_cart }}</p>
            </div>
        </div>
    </div>
  <div class="form-container">
    <div id="form-wrapper">
      <div id="payment-element"></div>
      <button type="submit" class="pay" :disabled="isStripeSubmitting" @click="handleSubmit()"
        :style="{ background: data.brand.whitelabel_config.buttonBackgroundColor, color: data.brand.whitelabel_config.buttonTextColor }">
        {{ data.text.pay }}
        {{ formatCurrency(stripeData.total_price / 100) }}
     
      </button>
    </div>
  </div>
</div>
</div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  props: ['data'],

  mounted() {
    this.loadStripe();
  },

  computed: {
    ...mapState(['stripeData', 'isStripeSubmitting', 'errorMessage']),
    ...mapGetters(['formatCurrency']),
  },

  methods: {
    ...mapActions(['loadStripe', 'handleSubmit', 'previousStep']),
  },
};
</script>

<style scoped>
.pay:disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.form-container {
  /* background: #F1F1F1; */
  min-height: 95vh;
  padding-top: 30px;
  overflow-x: hidden;
}

#form-wrapper {
  width: 84%;
  display: block;
  margin: auto;
  padding-bottom: 50px
}

button.pay {
  margin-top: 40px;
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 2px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 30px
}

.your-cart-text {
    padding-left: 16px;
    /* font-family: Figtree; */
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    display: flex;
    align-items: center;
    /* color: #000000; */
}

.svg-knob-elevation-and-text {
    margin-top: 30px;
    display: inline-flex;
}

/* .row.your-cart{
  background: #F1F1F1;
} */
</style>