<template>

    <div class="container">
        <div class="select-your-trip-container">
            <div class="select-your-trip-text-continer">
                <p class="select-your-trip-text" :style="{ color: data.brand.whitelabel_config.fontColor }"> {{ data.text.select_your_trip }}</p>
            </div>
            <div class="show-items-as-button"  @click="changeShowItemType()">

                
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="showItemsAsIcon">
                <rect x="0.603448" y="0.603448" width="28.7931" height="28.7931" rx="11.3966" :stroke="data.brand.whitelabel_config.fontColor" stroke-width="1.2069" />
                <path d="M11.0625 10.625C11.3672 10.625 11.625 10.8828 11.625 11.1875V12.3125C11.625 12.6406 11.3672 12.875 11.0625 12.875H9.9375C9.60938 12.875 9.375 12.6406 9.375 12.3125V11.1875C9.375 10.8828 9.60938 10.625 9.9375 10.625H11.0625ZM20.25 11C20.6484 11 21 11.3516 21 11.75C21 12.1719 20.6484 12.5 20.25 12.5H13.5C13.0781 12.5 12.75 12.1719 12.75 11.75C12.75 11.3516 13.0781 11 13.5 11H20.25ZM20.25 14.75C20.6484 14.75 21 15.1016 21 15.5C21 15.9219 20.6484 16.25 20.25 16.25H13.5C13.0781 16.25 12.75 15.9219 12.75 15.5C12.75 15.1016 13.0781 14.75 13.5 14.75H20.25ZM20.25 18.5C20.6484 18.5 21 18.8516 21 19.25C21 19.6719 20.6484 20 20.25 20H13.5C13.0781 20 12.75 19.6719 12.75 19.25C12.75 18.8516 13.0781 18.5 13.5 18.5H20.25ZM9.375 14.9375C9.375 14.6328 9.60938 14.375 9.9375 14.375H11.0625C11.3672 14.375 11.625 14.6328 11.625 14.9375V16.0625C11.625 16.3906 11.3672 16.625 11.0625 16.625H9.9375C9.60938 16.625 9.375 16.3906 9.375 16.0625V14.9375ZM11.0625 18.125C11.3672 18.125 11.625 18.3828 11.625 18.6875V19.8125C11.625 20.1406 11.3672 20.375 11.0625 20.375H9.9375C9.60938 20.375 9.375 20.1406 9.375 19.8125V18.6875C9.375 18.3828 9.60938 18.125 9.9375 18.125H11.0625Z" 
                :fill="data.brand.whitelabel_config.fontColor" />
                </svg>


                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
                <rect x="0.603448" y="0.603448" width="28.7931" height="28.7931" rx="11.3966" :stroke="data.brand.whitelabel_config.fontColor" stroke-width="1.2069"/>
                <path d="M18.75 10.25C19.5703 10.25 20.25 10.9297 20.25 11.75V19.25C20.25 20.0938 19.5703 20.75 18.75 20.75H11.25C10.4062 20.75 9.75 20.0938 9.75 19.25V11.75C9.75 10.9297 10.4062 10.25 11.25 10.25H18.75ZM18.75 11.75H15.75V14.75H18.75V11.75ZM18.75 16.25H15.75V19.25H18.75V16.25ZM14.25 14.75V11.75H11.25V14.75H14.25ZM11.25 19.25H14.25V16.25H11.25V19.25Z"
                :fill="data.brand.whitelabel_config.fontColor"/>
                </svg>

                <!-- <img src="../../assets/images/show-items-as-list.png" > -->
                <!-- <img src="../../assets/images/show-items-as-icon.png" v-else> -->
            </div>
             
        </div>
        <AncillaryFlightsViewD :data="data" :journeys="journeys" :moment="moment" :selectedJourneyIndex="selectedJourneyIndex" :apiAirportUrl="apiAirportUrl" v-if="showItemsAsIcon"></AncillaryFlightsViewD> 
        <AncillaryFlightsListViewD :data="data" :journeys="journeys" :moment="moment" :apiAirportUrl="apiAirportUrl" v-else></AncillaryFlightsListViewD>
    
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AncillaryFlightsViewD from "./AncillaryFlightsView.vue";
import AncillaryFlightsListViewD from "./AncillaryFlightsListView.vue";
export default {
    components: {
        AncillaryFlightsViewD,
        AncillaryFlightsListViewD,
    },
    
    props: ['data', 'journeys', 'moment', 'showItemsAsIcon', 'selectedJourneyIndex', 'apiAirportUrl'],
    methods: {
        ...mapActions(['changeShowItemType']),
   
  },
  

}
</script>

<style scoped>

.select-your-trip-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 63px;
}

.select-your-trip-text-continer {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;


}

.select-your-trip-text {
    font-size: 18px;
    font-weight: 600;
    line-height: 24.59px;
    text-align: center;
    /* color: #000000; */
    margin-bottom: 0px;
}


.show-items-as-button {
    margin-left: auto;
    cursor: pointer;
}

.show-items-as-button img {
    width: 30px;
    height: 30px;

}
</style>