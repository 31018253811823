<template>

    <div class="thank-you">
        <p class="text-center thank-you-text" :style="{ color: data.brand.whitelabel_config.fontColor }">{{ data.text.payment_unsuccessful }}</p>
        <p class="text-center your-order-is-confirmed-text mb-0" :style="{ color: data.brand.whitelabel_config.fontColor }">{{ data.text.please_ensure_your_bank_account_has_sufficient_balance_and_try_again }}</p>

        <div class="text-center mb-4">

            <button @click="reloadToPath({path:data.request_id})" type="button" class="go-to-home-button mb-3"
            :style="{ background: data.brand.whitelabel_config.buttonBackgroundColor, color: data.brand.whitelabel_config.buttonTextColor }">{{ data.text.try_again }}</button>

        </div>
    </div>
</template>


<script>
import { mapActions } from "vuex";
export default {
    props: ['data'],

    methods: {
        ...mapActions(['reloadToPath']),

  }
};
</script>

<style scoped>
.thank-you-text {
    margin-top: 50px;
    font-weight: normal;
    font-style: normal;
    font-size: 44px;
    line-height: 56px;
    letter-spacing: 0px;
    /* color: #252A31; */
    opacity: 1;
}

.your-order-is-confirmed-text {
    font-weight: normal;
    font-style: normal;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0px;
    /* color: #252A31; */
    opacity: 1;
    width: 100%
}

.go-to-home-button {
    margin-top: 40px;
    height: 40px;
    width: 100%;
    max-width: 400px;
    border: none;
    border-radius: 2px;
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    margin-bottom: 30px;
    width: 90%;

}

.thank-you {
    /* background: white; */
    min-height: 100vh;
    padding: 0px;
    overflow-x: hidden;
}
</style>