<template>

  <div class="modal fade p-0" :id="`addPassengerModal${journeyIndex}${serviceIndex}`" tabindex="-1"
    aria-labelledby="addPassengerModalLabel" aria-hidden="true">
    <div class="add-passenger-modal modal-dialog modal-dialog-centered">
      <div class="modal-content" :style="{ background: data.brand.whitelabel_config.modalBackgroundColor }">
        <button type="button" :class="`btn-close-${journeyIndex}${serviceIndex}`" data-bs-dismiss="modal"
          aria-label="Close" hidden></button>
        <div class="modal-body pb-0 ps-0 pe-0">
          <div class="row m-0">
            <div class="col-1 p-0">
            </div>
            <div class="col-10 p-0" v-if="data.brand.whitelabel_config.addAllPassengersFlights == true">
              <p class="for-which-passengers pt-3 pb-3 mb-0" :style="{ color: data.brand.whitelabel_config.fontColor }">
                {{ data.serviceTexts[service.type].add_attribute_for_the_flights_for_all_the_passengers_below }}
              </p>
              <div class="row p-0 m-0">
                <div class="col-6 passenger-name-container" v-for="(passenger, passengerIndex) in service.passengers"
                  :key="passengerIndex">
                  <button class="passenger-name" :disabled="passenger.added" :style="{
                    background: data.brand.whitelabel_config.passengerButtonBackgroundColor,
                    color: data.brand.whitelabel_config.fontColor,
                    border: '1.21px solid ' + data.brand.whitelabel_config.fontColor
                  }"> {{ passenger.name }} {{ passenger.last_name }}

                    <svg v-show="passenger.added" xmlns="http://www.w3.org/2000/svg" width="14"
                      style="margin-bottom: 2px;" viewBox="0 0 512 512">
                      <path :fill="data.brand.whitelabel_config.passengerSelectedColor"
                        d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </button>
                </div>

              </div>
              <div class="row pt-3 pb-3 m-0">
                <button class="add-passenger"
                  :style="{ background: data.brand.whitelabel_config.buttonBackgroundColor, color: data.brand.whitelabel_config.buttonTextColor, border: data.brand.whitelabel_config.buttonBackgroundColor }"
                  @click="addServiceForAllPassengersFlights({ currentJourneyIndex: journeyIndex, currentService: service, currentServiceIndex: serviceIndex })">{{
                    data.text.add }}</button>
              </div>
            </div>
            <div class="col-10 p-0" v-else>
              <p class="for-which-passengers pt-3 pb-3 mb-0" :style="{ color: data.brand.whitelabel_config.fontColor }">
                {{ data.serviceTexts[service.type].for_which_passengers_would_you_like_to_purchase_the }}
              </p>
              <div class="row p-0 m-0">
                <div class="col-6 passenger-name-container" v-for="(passenger, passengerIndex) in service.passengers"
                  :key="passengerIndex">
                  <button class="passenger-name" :disabled="passenger.added" :style="{
                    background: data.brand.whitelabel_config.passengerButtonBackgroundColor,
                    color: data.brand.whitelabel_config.fontColor,
                    border: '1.21px solid ' + data.brand.whitelabel_config.fontColor
                  }"
                    @click="addService({ journeyIndex: journeyIndex, service: service, serviceIndex: serviceIndex, passenger: passenger })">
                    {{ passenger.name }} {{ passenger.last_name }}

                    <svg v-show="passenger.added" xmlns="http://www.w3.org/2000/svg" width="14"
                      style="margin-bottom: 2px;" viewBox="0 0 512 512">
                      <path :fill="data.brand.whitelabel_config.passengerSelectedColor"
                        d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </button>
                </div>

              </div>
              <div class="row pt-3 pb-3 m-0">
                <button class="add-passenger"
                  :style="{ background: data.brand.whitelabel_config.buttonBackgroundColor, color: data.brand.whitelabel_config.buttonTextColor, border: data.brand.whitelabel_config.buttonBackgroundColor }"
                  @click="addServiceForAllPassengers({ journeyIndex: journeyIndex, service: service, serviceIndex: serviceIndex, passengers: service.passengers })">{{
                    data.text.add_for_all_passengers }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ['data', 'journeyIndex', 'service', 'serviceIndex'],

  methods: {
    ...mapActions(['addService', 'addServiceForAllPassengers', 'addServiceForAllPassengersFlights']),
  },

}
</script>

<style scoped>
.passenger-name-container {
  padding: 8px 4px 0px 0px;
}

.add-passenger {
  /* font-family: Figtree; */
  font-size: 12px;
  font-weight: 800;
  line-height: 14.4px;
  width: 100%;
  text-align: center;
  height: 50px;
  border-radius: 18px;
  margin-top: 13px;
  margin-bottom: 13px;
  text-transform: uppercase;
}

.passenger-name {
  width: 100%;
  min-height: 36px;
  border-radius: 12.07px;
  /* border: 1.21px solid #000000;
  background-color: white;
  color: #000000; */

  /* font-family: Figtree; */
  font-size: 14px;
  font-weight: 800;
  line-height: 16.8px;
  text-align: center;

}

.for-which-passengers {
  /* font-family: Figtree; */
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  /* color: #000000; */

}

.modal.fade .add-passenger-modal {
  transform: translate3d(0, 0, 0);
  align-items: flex-end;
  margin: 0px;
  --bs-modal-width: 100vw !important;
}

.modal.fade {
  --bs-modal-margin: 0rem;
}

.modal-content {
  /* min-height: 242px; */
  border-radius: 30px 30px 0px 0px;
}

.passenger-name:disabled {
  cursor: not-allowed !important;
  /* opacity: 0.5; */
}
</style>